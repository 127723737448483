import React from "react";

import Constants from '../data/constants'

import HelperLocalStorage from "../util/helperLocalstorage";

import { Switch, FormLabel, FormControl } from '@chakra-ui/react'


const Translit = () => {

	const [transliteration, setTransliteration] = React.useState(HelperLocalStorage.getIsTransliteration())

	console.log(transliteration)

	// Loop Value Change - update UI and Cache
	const loopChanged = () => {
		setTransliteration(!transliteration)
		localStorage.setItem(Constants.SETTING_Transliteration, JSON.stringify(!transliteration));
	}

	return (
		<>

			<FormControl display='flex' alignItems='center' mb={5}>

				<Switch id="transliteration"
					isChecked={transliteration}
					onChange={loopChanged}
				// disabled={playStatus}
				/>
				<FormLabel htmlFor='transliteration' mb='0' ml="1.5">
					Transliteration
				</FormLabel>

			</FormControl>

		</>

	)
}

export default Translit;
