import React from "react";

import Constants from '../data/constants'

import HelperLocalStorage from "../util/helperLocalstorage";

import { Switch, FormLabel, FormControl } from '@chakra-ui/react'




const RepeatAfterReciter = () => {

	const [repeatAfterReciter, setRepeatAfterReciter] = React.useState(HelperLocalStorage.getRepeatAfterReciter())

	const handleRepeatAfterReciter = () => {
		setRepeatAfterReciter(!repeatAfterReciter)
		localStorage.setItem(Constants.KEY_RepeatAfterReciter, JSON.stringify(!repeatAfterReciter))
	}


	return (

		<FormControl display='flex' alignItems='center' mb={5}>

			<Switch id="RepeatAfterReciter"
				isChecked={repeatAfterReciter}
				onChange={handleRepeatAfterReciter}
			/>
			<FormLabel htmlFor='RepeatAfterReciter' mb={0} ml={1.5}>
				Repeat after Reciter
			</FormLabel>

		</FormControl>
	)
}

export default RepeatAfterReciter;
