import { isMobile } from "react-device-detect"
import { RHAP_UI } from "react-h5-audio-player/lib/constants"



// Hide the play button from user when audio is not playing to avoid confusion
export const getAudioControls = (audioStopped: boolean) => {

	// In Mobile view we don't want volume controls ever
	if (isMobile) {

		// And when audio stopped we only want to show the main player
		if (!audioStopped) {
			return [RHAP_UI.MAIN_CONTROLS]
		}
		return []
	}
	else {

		if (!audioStopped) {
			return [RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]
		}
		return [RHAP_UI.VOLUME_CONTROLS]
	}
}

export default getAudioControls;