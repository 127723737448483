import HomeIntro from './home-intro'
import HomeFeatures from './home-features'

import { FaCodeBranch } from "react-icons/fa";
import { Center, Text } from "@chakra-ui/react";


const Home = () => {
	return (
		<>
			<HomeIntro />

			<HomeFeatures />

			<Center mb={5}>
				<FaCodeBranch />
				<Text>Version</Text>: {process.env.REACT_APP_VERSION}
			</Center>


		</>
	);
}

export default Home;