import { Text, Container, Heading, Box } from '@chakra-ui/react'


const sections =
	[
		{

			title: 'Choose Reciter',
			text: 'Choose from over 50 Reciters. Note the sound quality guideline, the higher the bitrate (e.g. 64Kbps) means high quality and therefore more bandwidth.'
		},
		{

			title: 'Choose Surah',
			text: 'Choose the Surah you wish to memorize. This takes you back to main page and the app updates the Start Verse and End Verse according to the number of verses in the surah. For e.g. in Surah Fatiha, Start Verse will be  1 and End Verse is 7.'
		},
		{

			title: 'Choose your Start and End Verse',
			text: 'This App provides you the option of choosing you own Start and End Choose Verse. This allows you to listen from Verse X to Verse Y from any Surah.'
		}
		,
		{

			title: 'Repeat each Verse',
			text: 'This will repeat each verse X number of times before moving onto the next verse, which will also be repeated X number of times until End Verse is reached.'
		},
		{

			title: 'Loop',
			text: 'Enabling the Loop, will cause your verse selection to continue on a loop until you press stop.'
		},
		{

			title: 'Repeat after Reciter?',
			text: 'Enabling this feature will play a verse that is X seconds long, and then wait for X seconds for you to repeat after the reciter and then continue to the next verse which will play similarly.'
		},
		{
			title: 'Pause between verses',
			text: 'This will pause X number of seconds between verses. Note if you enable repeat after reciter, that will occur first and then this pause.'
		},
		{
			title: 'Speed Control',
			text: 'You can change the speed at any time including during playback.'
		},
		{

			title: 'Read Mode',
			text: 'You can flip between the main screen and Quran Text anytime, including during playback.'
		},
		{

			title: 'Screen Wake Lock',
			text: 'A setting to prevent devices from dimming, locking or turning off the screen while a lesson is playing'
		}
	]

const Help = () => {

	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading>Guide</Heading>

			<Text>Brief guideline to help you get started...</Text>

			{sections.map((section, index) => (
				<Box mt={10} key={index}>
					<Heading as="h2" fontSize={"2xl"} mb={2}>{section.title}</Heading>
					<Text>{section.text}</Text>
				</Box>
			))}

		</Container >
	)
}

export default Help;