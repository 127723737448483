import { useEffect, useState, useRef } from "react";

// Audio Player Lib
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// Data / Util
import HelperLocalStorage from "../util/helperLocalstorage"
import AudioUtil from "./audio-util";
import AudioCustomControls from "./about-custom-controls";

// Components
import RepeatAfterReciterTimer from './audio-repeater-timer'
import QuranText from './quran-text'
import AudioSpeed from './audio-speed'


// UI
import { Box, Center, Container, Flex, IconButton, Spacer, Text } from "@chakra-ui/react";
import { FaBook, FaCog, FaPlay, FaStop } from "react-icons/fa";

// Wake Lock
import { useWakeLock } from 'react-screen-wake-lock';



/*  
		
		Functions: 3x Key Functions

		1. PlayAudio 
		2. StopAudio
		3. onEnded

*/


type Props = {
	onPlayStatusChange: Function, // Lets the Parent Component know if the Audio is Playing
	displayQuranText: boolean, // Parent/Child knows if the Quran Text is to be shown or not
	setDisplayQuranText: Function
	verseCurrent: number
	setCurrentVerse: Function
}


const Audio = ({ onPlayStatusChange, displayQuranText, setDisplayQuranText, verseCurrent, setCurrentVerse }: Props) => {


	let refRAP = useRef<AudioPlayer>(null);
	const verseInterval = HelperLocalStorage.getVerseInterval()

	const [verseRepeatCounter, setVerseRepeatCounter] = useState<number>(1); // Used to track repeating of verses
	const [currentAudioDuration, setCurrentAudioDuration] = useState<number>(0); // Audio Duration in seconds
	const [repeatAfterReciterMessage, setRepeatAfterReciterMessage] = useState<boolean>(false); // To show/hide the Repeater After Reciter feature
	const [audioSource, setAudioSource] = useState<string>(''); // Audio Source URL
	const [audioStopped, setAudioStopped] = useState<boolean>(true); // Audio Stopped, used to enable/disable stop button
	const [playbackSpeed, setPlaybackSpeed] = useState<number>(HelperLocalStorage.getPlayBackSpeed())

	const [verseIntervalCounter, setVerseIntervalCounter] = useState<boolean>(false);
	const [verseIntervalCurrent, setVerseIntervalCurrent] = useState<number>(0);

	// 	Screen Wake Lock API.
	// It provides a way to prevent devices from dimming or locking the screen when an application needs to keep running. 

	const { isSupported, released, request, release } = useWakeLock({

		onRequest: () => { },

		onError: () => { },

		onRelease: () => { },
	});



	// https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep/39914235#39914235
	const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

	/* 
		Play Button is Pressed. Default is whatever is in the state current verse or param verse number
		A: Thus, the 1st playback where it simply needs to play the current verse
		B: OnAudioEnded() handles the scenarios where it may need to play the next verse or repeat current verse and so on hence the param.
	*/
	const PlayAudio = (incomingVerseCurrent = verseCurrent) => {
		// Lift State to Parent. This blocks the Config screen from "sending" new props/state
		onPlayStatusChange(true);

		// console.log("incomingVerseCurrent = " + incomingVerseCurrent)
		// console.log("***** PlayAudio > STARTED ***** ")

		setAudioSource('')
		setAudioSource(AudioUtil.getAudioURL(incomingVerseCurrent))
		setCurrentVerse(incomingVerseCurrent)
		setAudioStopped(false)

		// console.log("***** PlayAudio > ENDED ***** ")
		// console.log("")

		// Wake Lock API

		// wake lock app setting in local storage
		// if its set to false then dont request
		// this needs to be added to the conditional below
		const isWakeLockEnabled = HelperLocalStorage.getWakeLockSetting()

		// react-wake-lock lib logic
		// initial value of released is undefined so when requesting wake lock 
		// and setting released to true we first need
		// to check if released is undefined or not already released

		// use juggle check in the or for undefined or null

		isSupported && isWakeLockEnabled && (released == null || released === true) && request()
	}


	/*
		STOP: Basically Reset all Variables
	*/
	const StopAudio = () => {

		// console.log("FUNCTION: StopAudio() >>> STARTED:")

		// Lift State to Parent / Now the Main UI can be made visible again
		onPlayStatusChange(false);

		// Set the Audio to Stopped in case it is used elsewhere for checking
		// Remove the Source URL - in case user pressed start/play after stopping
		setAudioStopped(true)
		setAudioSource('')

		// Current Audio Duration make it zero
		setCurrentAudioDuration(0)

		// Turn of Repeat after Reciter Message
		setRepeatAfterReciterMessage(false)
		setVerseRepeatCounter(1)
		setCurrentVerse(HelperLocalStorage.getVerseStart())


		// console.log("FUNCTION: StopAudio() >>> ENDED:")

		// wake lock api
		isSupported && released === false && release()
	}




	async function verseIntervalChecker() {

		for (let i = 1; i <= verseInterval; i++) {
			setVerseIntervalCounter(true)
			setVerseIntervalCurrent(i)
			console.log(`Waiting ${i} seconds...`);
			await sleep(i * 1000);
		}
		console.log('Done');
		setVerseIntervalCounter(false)
		setVerseIntervalCurrent(0)

		if (!audioStopped) {
			audioEnded()
		}
	}


	// Handles the logic for when the audio as played til the end
	const audioEnded = (RepeatAfterReciterCheck: boolean = true) => {

		// 1. Repeat after Reciter will be stored in Local Storage or we use our default
		if (HelperLocalStorage.getRepeatAfterReciter() && RepeatAfterReciterCheck) {

			// Extract Duration of Audio from Player
			const reactPlayer = refRAP.current?.audio
			const duration = reactPlayer?.current?.duration

			// This should never be the case though, we then update the related state. This is to make typescript happy!
			if (duration !== undefined) {

				setCurrentAudioDuration(Math.ceil(duration))

				// This will trigger the child component which will handle the message for repeat after reciter
				// Once complete, it will call audioEnded() but will boolean === false and thus the conditional will be ignored
				setRepeatAfterReciterMessage(true)

				// the timer is async, so we need to exit this function and wait for it to be called again by the child component
				return
			}
			else {
				console.log("BUG: With audio or something wrong.. contact developer")
			}
		}

		// 2. Lets check if we have to repeat the verse, if so we can initiate playback and exit function.
		if (HelperLocalStorage.getRepeatEachVerse() > verseRepeatCounter) {
			// console.log("Repeating")
			setVerseRepeatCounter(verseRepeatCounter + 1)
			setAudioSource('')
			return PlayAudio()
		}
		/*
						 NO Repetion of Verses i.e. 1=1 					 
						 OR
						 verseRepeatCounter = verseRepeat i.e. no more repetition of verse required.
		*/
		else {// Reset Counter but continue below.
			setVerseRepeatCounter(1)
		}


		// 3. Check if LOOP is TRUE AND end verse === current verse
		if (verseCurrent === HelperLocalStorage.getVerseEnd()) {

			// If we in a loop we reset the current verse
			if (HelperLocalStorage.getIsLoopSelectionOn()) {

				// console.log("LOOP: TRUE...")
				// console.log("LOOP: Verse Current must thus be made to === Verse Start")
				setCurrentVerse(HelperLocalStorage.getVerseStart())
				// console.log("HelperLocalStorage.getVerseStart() = " + HelperLocalStorage.getVerseStart())
				return PlayAudio(HelperLocalStorage.getVerseStart())
			}

			// Nothing to do, lesson is complete as we not looping
			else {
				StopAudio()
				return
			}
		}

		setAudioSource('')
		PlayAudio(verseCurrent + 1)

		// console.log("**** audioEnded() - END ****")
	}


	// Track Volume Change
	const [audioVolume, setAudioVolume] = useState<number>(1)
	const handleVolumeChanged = () => {
		const reactPlayer = refRAP.current?.audio
		const newVolume = reactPlayer?.current?.volume
		if (newVolume !== undefined) {
			setAudioVolume(newVolume)
		}
	}


	/*
	
		Hooks
	
	*/

	// Note: User could update the Audio Player Speed pre/post or while audio is playing! Async, to make it non-blocking
	useEffect(() => {

		const asyncSpeedChange = async () => {

			const reactPlayer = refRAP.current?.audio
			if (reactPlayer?.current?.playbackRate !== undefined) {
				reactPlayer.current.playbackRate = playbackSpeed
			}
		}

		asyncSpeedChange()

	}, [playbackSpeed, audioSource])



	return (
		<>

			{
				// Displays the Quran Text. The Verse Current Props makes the auto scroll feature possible
				displayQuranText && (
					<QuranText currentVerse={verseCurrent} />
				)
			}



			{
				// Footer Audio Controls
			}
			<Container position={"fixed"} bottom={0} maxW={"full"} p={0} backgroundColor={"black"} >



				<Container maxW="container.sm" p={1}>

					{
						verseIntervalCounter &&
						<Box>
							<Center>
								<Text p={5} color={"white"}>Verse Pause: {verseIntervalCurrent}</Text>
							</Center>
						</Box>
					}


					<Flex>

						<AudioSpeed
							playbackSpeed={playbackSpeed}
							setPlaybackSpeed={setPlaybackSpeed}
						/>

						<Spacer />

						<IconButton
							colorScheme='green'
							aria-label='Play Now'
							onClick={() => PlayAudio()}
							disabled={!audioStopped}
							icon={<FaPlay />}
							mr={2}
						/>

						<IconButton
							colorScheme='red'
							aria-label='Stop Playback'
							onClick={StopAudio}
							disabled={audioStopped}
							icon={<FaStop />}
						/>

						<Spacer />

						<Center>
							{
								// Count Down Timer
								repeatAfterReciterMessage && (
									<RepeatAfterReciterTimer
										audioEnded={audioEnded}
										duration={currentAudioDuration}
										setRepeatAfterReciterMessage={setRepeatAfterReciterMessage}
									/>
								)
							}
						</Center>

						<Spacer />

						<IconButton
							colorScheme='orange'
							aria-label='Quran Text / Settings Toggle'
							onClick={() => setDisplayQuranText(!displayQuranText)}
							icon={displayQuranText === true ? <FaCog /> : <FaBook />}
						/>

					</Flex>

				</Container>



				<AudioPlayer
					ref={refRAP}
					src={audioSource}
					onEnded={() => verseIntervalChecker()}
					autoPlayAfterSrcChange
					onVolumeChange={() => handleVolumeChanged()}
					volume={audioVolume}
					layout={"horizontal"}
					customControlsSection={AudioCustomControls(audioStopped)}
				/>


			</Container>

		</>
	);
}

export default Audio;