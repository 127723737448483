const features =
	[{
		id: 0,
		title: 'Content',
		text: 'Verse by verse playback by over 50 reciters. Control playback through looping, Repetition of verses and auto pausing between verses. Reading mode with auto scroll to follow the reciter.'
	},
	{
		id: 1,
		title: 'Technology',
		text: 'May be accessed from your desktop, tablet, mobile phone, television and other devices. Compatible on most platforms including Android, iOS, Windows, Linux and more.'
	},
	{
		id: 2,
		title: 'Install',
		text: 'No Ads and completely FREE. To install, simply acecpt “Add to Home Screen” via pop-up or via Browser Menu. Yes! Its that simple. No App Stores involved. Note, some browsers require manual installation as iOS Safari.'
	}
	]

export default features