import './App.css';
import './css/tajweed.css'
import './css/arabic.css'

import { ChakraProvider, ThemeConfig } from '@chakra-ui/react'

// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"

import AppRoutes from './util/routes'

// 2. Add your color mode config
const config: ThemeConfig = {
	initialColorMode: 'light',
	useSystemColorMode: false,
}

const theme = extendTheme({
	config, fonts: {
		heading: 'Open Sans, sans-serif',
		body: 'Raleway, sans-serif',
	},
})


function App() {

	return (

		<ChakraProvider theme={theme} >

			<div className="App">

				<AppRoutes />

			</div>

		</ChakraProvider>
	);
}

export default App;
