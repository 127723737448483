import { Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';


type Props = {
	duration: number, // time to pause for
	audioEnded: Function, // psuedo call back
	setRepeatAfterReciterMessage: Function // Updates parent UI to close this component
}


const RepeatAfterReciterTimer = ({ duration, audioEnded, setRepeatAfterReciterMessage }: Props) => {

	const [StateTimer, setStateTimer] = useState(duration)

	// in UseEffect we take the duration and every second we reduce value until zero
	useEffect(() => {

		// console.log("duration = " + duration)

		// Every second to decrement the timer
		const currentAudioDurationTimer = setInterval(() => {

			// console.log("StateTimer = " + StateTimer)

			if (StateTimer === 0) {
				clearInterval(currentAudioDurationTimer);
				setRepeatAfterReciterMessage(false)
				audioEnded(false)
			}
			else {
				setStateTimer(prevStateTimer => prevStateTimer - 1)

			}
		}, 1000)

		return () => {
			clearInterval(currentAudioDurationTimer);
		}
	});


	return (
		<Text width="15px" fontSize="12px" color={"white"}>{StateTimer}</Text>
	)
}

export default RepeatAfterReciterTimer;