import { Box, Button, Container, Heading, Link, Text } from "@chakra-ui/react";

import { FaExternalLinkAlt } from "react-icons/fa";

import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from '@chakra-ui/react'


const Contact = () => {
	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading as='h1' mb={5}>Contact</Heading>

			<Accordion mb={5}>
				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex='1' textAlign='left'>
								Please add Reciter to Memorize Quran</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						Unfortunately, we cannot add more reciters unless we are provided with the reciters audio broken up into verse by verse recordings.

						Each reciter on MQ has over 6000 Audio MP3s files EACH!

						If you  have this done for the reciter you are requesting it for, we can then add it.					</AccordionPanel>
				</AccordionItem>

				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex='1' textAlign='left'>
								What is the size of this App?
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						The App is around 1MB compressed (download size) and around 7MB once it is uncompressed onto your device. This excludes audio which is over 0.5TB</AccordionPanel>
				</AccordionItem>


				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex='1' textAlign='left'>
								Can this App be used offline?
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						It can be installed on mobile phones but an active internet connection is required for audio playback. Some devices may cache previously played audio and thus be available offline, but our App does not control this.</AccordionPanel>
				</AccordionItem>


				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex='1' textAlign='left'>
								I cannot find this App on the App Stores?</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						This is a Web App, not a Mobile App. It works on Desktop, Mobiles and Tablets. You can install it without the App Store.</AccordionPanel>
				</AccordionItem>

			</Accordion>

			<Box mb={5}>
				<Text>Please keep inquiries directly, related to our project.</Text>
			</Box>

			<Box mb={5}>
				<Link href='mailto:support@muslimcentral.com?subject=MemorizeQuran.com:' isExternal>
					<Button leftIcon={<FaExternalLinkAlt />}>
						Email Support
					</Button>
				</Link>
			</Box>

		</Container >
	)
}

export default Contact;