import React, { useState } from "react";

import Constants from '../data/constants'

import HelperLocalStorage from "../util/helperLocalstorage";

import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	HStack
} from '@chakra-ui/react'

/*
	
	Updates the Number of times a verse is to be repeated
	verseRepeat - is the user defined number of times a verse must be repeated

*/

type Props = {
	setPreventPlayback: Function
}

const minInput: number = 1
const maxInput: number = 99


const RepeatVerse = ({ setPreventPlayback }: Props) => {

	const [verseRepeat, setVerseRepeat] = React.useState(HelperLocalStorage.getRepeatEachVerse())

	const [verseRepeatInputError, setVerseRepeatInputError] = useState(false);


	const verseRepeatVerseChanged = (event: any) => {

		// console.log({ event })

		let val = parseInt(event)

		setVerseRepeat(event)

		// Valid Entry
		if (val >= minInput && val <= maxInput) {

			setPreventPlayback(false)
			setVerseRepeatInputError(false)
			localStorage.setItem(Constants.KEY_RepeatVerses, JSON.stringify(val))
		}

		// User removed any entry in the input field or entered an invalid value
		else {
			setPreventPlayback(true)
			setVerseRepeatInputError(true)
		}

	}

	return (

		<FormControl mb={5}>

			<HStack>

				<FormLabel minWidth={40} htmlFor='repeat-verse'>Repeat each Verse</FormLabel>

				<NumberInput
					id="repeat-verse"
					inputMode={"numeric"}
					min={minInput}
					max={maxInput}
					value={verseRepeat}
					onChange={verseRepeatVerseChanged}					
					maxW={150}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>

			</HStack>

			{verseRepeatInputError ? (
				<FormHelperText>
					Range [{minInput.toString()}-{maxInput.toString()}]
				</FormHelperText>
			) : (
				<FormErrorMessage>Input is required.</FormErrorMessage>
			)}

		</FormControl>

	)
}

export default RepeatVerse;
