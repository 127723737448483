import { Box, Container, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";


const Changelog = () => {

	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading>Changelog</Heading>

			<Heading as="h2" size={"sm"}>Latest Version: {process.env.REACT_APP_VERSION}</Heading>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.9.5 [April 2024]</Heading>
				<UnorderedList>
					<ListItem>Transliteration Support</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.9.2 [Sep 2023]</Heading>
				<UnorderedList>
					<ListItem>Code Improvements </ListItem>
					<ListItem>FIX: Horizontal Scroll Issue </ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.9.1 [Feb 2023]</Heading>
				<UnorderedList>
					<ListItem>Code Improvements </ListItem>
					<ListItem>FIX: With Repeat after verse on, you can now see the last verse. (Reported by A. Sirajudeen) </ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.8</Heading>
				<UnorderedList>
					<ListItem>NEW: Added Screen Wake lock setting after more testing.</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.7</Heading>
				<UnorderedList>
					<ListItem>APP: Some updates were causing bugs so we reverted to an older configuration until we can test it some more.</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.6</Heading>
				<UnorderedList>
					<ListItem>NEW: Add manual switch for the screen wake lock setting</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.5</Heading>
				<UnorderedList>
					<ListItem>CODE: Updated app dependencies</ListItem>
					<ListItem>CODE: Migrate to new APP architecture</ListItem>
					<ListItem>NEW: Add Wake Lock API to prevent screen from dimming or locking (User Feature Request)</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.4</Heading>
				<UnorderedList>
					<ListItem>NEW: Pause between Verses (seconds) (User Feature Request)</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.4</Heading>
				<UnorderedList>
					<ListItem>NEW: Pause between Verses (seconds) (User Feature Request)</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.3</Heading>
				<UnorderedList>
					<ListItem>UI: Added a 'Reset to Default Reciter' button on the Reciters Page.</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0.2</Heading>
				<UnorderedList>
					<ListItem>BUG: Fixed Loop of Single Verse as reported (Thanks: Ibrahim E.)</ListItem>
					<ListItem>CODE: Fixed GA issue</ListItem>
					<ListItem>CODE: Updated Service Worker</ListItem>
					<ListItem>CODE: Minor clean-ups</ListItem>
					<ListItem>UI: Improved Fonts</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V2.0 [May 2022]</Heading>
				<Text>Version 2 is a new major release. Lots of internal changes mostly though.</Text>
				<UnorderedList>
					<ListItem>3x Quran Fonts</ListItem>
					<ListItem>Dark Mode</ListItem>
					<ListItem>Improved Input Fields with incremental selectors</ListItem>
					<ListItem>Bug Fixes and Enhancement</ListItem>
					<ListItem>UI: General Improvements</ListItem>
					<ListItem>UI: Added a Loading Screen when Quran Text is being fetched</ListItem>
					<ListItem>Updated Contact Page</ListItem>
					<ListItem>Updated About Page</ListItem>
					<ListItem>Updated Donate Page</ListItem>
					<ListItem>Updated Front Page</ListItem>
					<ListItem>Updated Guide Page</ListItem>
					<ListItem>NEW Changelog Page</ListItem>
					<ListItem>Code: Performance Optimization</ListItem>
					<ListItem>Code: Changes to make it easier to maintain and upgrade</ListItem>
					<ListItem>Code: Updated underlying techstack</ListItem>
					<ListItem>Code: Less Data usage with Caching of Quran Text</ListItem>
				</UnorderedList>

			</Box>

			<Box mt={5}>
				<Heading as="h2" fontSize={"medium"}>V1.9.1</Heading>
				<UnorderedList>
					<ListItem>Many code changes to improve speed and reliability</ListItem>
					<ListItem>Bug Fix: Volume kept reverting to default value between verses.</ListItem>
					<ListItem>Now supports Indopak, Uthmani and Uthmani (Color Coded Tajweed)</ListItem>
					<ListItem>Bug Fixes and Enhancement</ListItem>
					<ListItem>Switcher between Listen only and Read & Listen Modes "always visible"</ListItem>
				</UnorderedList>
			</Box>

		</Container >

	);
}

export default Changelog;