import HelperReciters from "../util/helperReciters"
import HelperLocalStorage from "../util/helperLocalstorage"
import Constants from '../data/constants'


export module AudioUtil {

	// Determines the URL to Play based on Verse Number
	export const getAudioURL = (thisVerseCurrent: number) => {

		// Get Reciter ID and then Reciter Data
		const reciterID = HelperLocalStorage.getReciterID()
		const reciter = HelperReciters.getReciterData(reciterID)

		// Surah String for URL
		// e.g. 000 + 1 (Fatiha) becomes > 001
		const surahID = HelperLocalStorage.getSurahID()
		const tmpSuraNumber: string = "000" + surahID.toString()
		const surahNumber = tmpSuraNumber.slice(-3);

		// Verse String for URL 
		// e.g. 000 + 1 becomes > 001
		const tmpVerseNumber = "000" + (thisVerseCurrent).toString();
		const verseNumber = tmpVerseNumber.slice(-3);

		// Final URL
		// abdul basit - surah 1 (fatihah) - verse 1
		// e.g. https://audio.memorizequran.app/abdul-basit/001001.mp3
		const URL = Constants.BASEURL + reciter.subfolder + "/" + surahNumber + verseNumber + ".mp3"

		return URL
	}

}

export default AudioUtil