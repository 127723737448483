import { Link as RouterLink } from 'react-router-dom';

import {
	Box,
	Heading,
	Container,
	Text,
	Button,
	Stack,
	Link
} from '@chakra-ui/react';
import { FaBrain } from 'react-icons/fa';



export default function HomeIntro() {
	return (

		<Container maxW={'3xl'}>
			<Stack
				as={Box}
				textAlign={'center'}
				spacing={{ base: 8, md: 14 }}
				py={{ base: 20, md: 36 }}>
				<Heading
					fontWeight={600}
					fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
					lineHeight={'110%'}>
					Memorize Quran
					<br />
					<Text as={'span'} color={'red.400'}>
						Desktop, Tablet & Mobile Friendly</Text>
				</Heading>
				<Text fontSize={'xl'}>
					FREE & Easy to Use.
				</Text>
				<Stack
					direction={'column'}
					spacing={3}
					align={'center'}
					alignSelf={'center'}
					position={'relative'}>

					<Link as={RouterLink} to="/main">
						<Button
							leftIcon={<FaBrain />}
							colorScheme={'red'}
							bg={'red.400'}
							rounded={'full'}
							px={6}
							_hover={{
								bg: 'red.500',
							}}
						>Start Memorizing
						</Button>
					</Link>

				</Stack>
			</Stack>
		</Container>

	);
}

