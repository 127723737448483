// get reciter
import Constants from "../data/constants";

export module HelperLocalStorage {
	/* 
	
		GENERAL PATTERN
	
		1. Local Storage: Attempt to Get Value or return empty
		2. If a Value Returns, we use it
		3. Else we use the default from our constants interface

		The 1st time user will thus ALWAYS get a valid value returned.
		The local storage will be empty on first run.
		These get methods can be used globally

		** TypeScript localStorage.getItem(Constants.KEY_ReciterID) || ''
		If nothing is in the local storage then we return an empty string
		As below, we check BEFORE parsing the string to number, boolean etc

		NOTE:
		Components may get data from here, but somewhere local storage must be updated after user changes

	*/

	/*

		GET: Reciter ID

	*/

	export function getReciterID(): number {
		const string_reciterID: string =
			localStorage.getItem(Constants.KEY_ReciterID) || "";

		if (string_reciterID === "") {
			return Constants.DefaultReciterID;
		}
		return JSON.parse(string_reciterID);
	}

	/*

		GET: Surah ID

	*/
	export function getSurahID(): number {
		const string_surahID: string =
			localStorage.getItem(Constants.KEY_SurahID) || "";

		if (string_surahID === "") {
			return Constants.DefaultSurahID;
		}
		return JSON.parse(string_surahID);
	}

	/*

		GET: START Verse

	*/
	export function getVerseStart(): number {
		const string_verseStart: string =
			localStorage.getItem(Constants.KEY_VerseStart) || "";

		if (string_verseStart === "") {
			return Constants.DefaultVerseStart;
		}

		return JSON.parse(string_verseStart);
	}

	/*

		GET: END Verse

	*/
	export function getVerseEnd(): number {
		const string_verseEnd: string =
			localStorage.getItem(Constants.KEY_VerseEnd) || "";

		if (string_verseEnd === "") {
			return Constants.DefaultVerseEnd;
		}

		return JSON.parse(string_verseEnd);
	}

	/*

		GET: Repeat Each Verse

	*/
	export function getRepeatEachVerse(): number {
		const string_repeatEachVerse: string =
			localStorage.getItem(Constants.KEY_RepeatVerses) || "";

		if (string_repeatEachVerse === "") {
			return Constants.DefaultVerseRepeat;
		}

		return JSON.parse(string_repeatEachVerse);
	}

	/*

	GET: Loop Selection

	*/
	export function getIsLoopSelectionOn(): boolean {
		const string_loopSelection: string =
			localStorage.getItem(Constants.KEY_LoopListenMode) || "";

		if (string_loopSelection === "") {
			return Constants.DefaultLoopListenMode;
		}

		return JSON.parse(string_loopSelection);
	}

	/*
	
		GET: Repeat After Reciter
	
		*/
	export function getRepeatAfterReciter(): boolean {
		const string_RepeatAfterReciter: string =
			localStorage.getItem(Constants.KEY_RepeatAfterReciter) || "";

		if (string_RepeatAfterReciter === "") {
			return Constants.DefaultRepeatAfterReciter;
		}

		return JSON.parse(string_RepeatAfterReciter);
	}

	/*

		GET: Quran Font

	*/
	export function getQuranFont(): string {
		const quranFont: string =
			localStorage.getItem(Constants.KEY_QuranFontCurrent) || "";
		// console.log("quranFont = " + quranFont)

		if (quranFont === "") {
			console.log("nothing in the local storage, using app default");
			return Constants.KEY_QuranFontIndopak;
		}
		return quranFont;
	}

	/*

		GET: Playback Speed
		JSON.parse(localStorage.getItem(Constants.KEY_PlaybackSpeed))

	*/
	export function getPlayBackSpeed(): number {
		const string_playbackspeed: string =
			localStorage.getItem(Constants.KEY_PlaybackSpeed) || "";

		if (string_playbackspeed === "") {
			return 1; // Default Speed
		}

		return JSON.parse(string_playbackspeed);
	}

	/*

		GET: Verse Interval		

	*/
	export function getVerseInterval(): number {
		const string_verseInterval: string =
			localStorage.getItem(Constants.KEY_VerseInterval) || "";

		if (string_verseInterval === "") {
			return 0; // Default Pause between Verses
		}

		return JSON.parse(string_verseInterval);
	}

	/*

		Wake Lock Setting: Getter & Setter

	*/
	export function getWakeLockSetting(): boolean {
		const stringWakeLockSetting: string =
			localStorage.getItem(Constants.SETTING_WAKE_LOCK) || "";

		if (stringWakeLockSetting === "") {
			return false; // Default Wake Lock
		}

		if (stringWakeLockSetting === "false") {
			return false
		}

		return true
	}

	export function setWakeLock(wakeLockSetting: boolean) {
		localStorage.setItem(Constants.SETTING_WAKE_LOCK, String(wakeLockSetting))
	}



	/*

		Wake Lock Setting: Getter & Setter

	*/
	export function getIsTransliteration(): boolean {
		const propertyString: string =
			localStorage.getItem(Constants.SETTING_Transliteration) || "";

		if (propertyString === "") {
			return false; // Default
		}

		if (propertyString === "false") {
			return false
		}

		return true
	}
}

export default HelperLocalStorage;
