
interface Constants {
	KEY_ReciterID: string;
	KEY_SurahID: string;
	KEY_VerseStart: string;
	KEY_VerseEnd: string;
	KEY_RepeatVerses: string;
	KEY_LoopListenMode: string;
	KEY_RepeatAfterReciter: string;
	KEY_PlaybackSpeed: string;
	KEY_QuranFontIndopak: string;
	KEY_QuranFontUthmani: string;
	KEY_QuranFontUthmaniTajweed: string;
	KEY_QuranFontCurrent: string;
	KEY_VerseInterval: string;
	DefaultReciterID: number;
	DefaultSurahID: number;
	DefaultVerseStart: number;
	DefaultVerseEnd: number;
	DefaultVerseRepeat: number;
	DefaultLoopListenMode: boolean;
	DefaultRepeatAfterReciter: boolean;
	BASEURL: string;
	QuranAPI_Indopak: string;
	QuranAPI_Uthmani: string;
	QuranAPI_Uthmani_Tajweed: string;
	SETTING_WAKE_LOCK: string;
	SETTING_Transliteration: string;
}


const constants: Readonly<Constants> =
{
	"KEY_ReciterID": "MemorizeQuranApp_ReciterID",
	"KEY_SurahID": "MemorizeQuranApp_SurahID",
	"KEY_VerseStart": "MemorizeQuranApp_VerseStart",
	"KEY_VerseEnd": "MemorizeQuranApp_VerseEnd",
	"KEY_RepeatVerses": "MemorizeQuranApp_RepeatVerses",
	"KEY_LoopListenMode": "MemorizeQuranApp_LoopPlayback",
	"KEY_RepeatAfterReciter": "MemorizeQuranApp_RepeatAfterReciter",
	"KEY_PlaybackSpeed": "MemorizeQuranApp_PlaybackSpeed",
	"KEY_QuranFontIndopak": "MemorizeQuranApp_indopak",
	"KEY_QuranFontUthmani": "MemorizeQuranApp_uthmani",
	"KEY_QuranFontUthmaniTajweed": "MemorizeQuranApp_uthmani_tajweed",
	"KEY_QuranFontCurrent": "MemorizeQuranApp_Currrent_font",
	"KEY_VerseInterval": "MemorizeQuranApp_VerseInterval",
	"DefaultReciterID": 20,
	"DefaultSurahID": 1,
	"DefaultVerseStart": 1,
	"DefaultVerseEnd": 7,
	"DefaultVerseRepeat": 1,
	"DefaultLoopListenMode": false,
	"DefaultRepeatAfterReciter": false,
	"BASEURL": "https://audio.memorizequran.app/",
	"QuranAPI_Indopak": "https://api.quran.com/api/v4/quran/verses/indopak?chapter_number=",
	"QuranAPI_Uthmani": "https://api.quran.com/api/v4/quran/verses/uthmani?chapter_number=",
	"QuranAPI_Uthmani_Tajweed": "https://api.quran.com/api/v4/quran/verses/uthmani_tajweed?chapter_number=",
	SETTING_WAKE_LOCK: "MemorizeQuranApp_SETTING_WAKE_LOCK",
	SETTING_Transliteration: "MemorizeQuranApp_SETTING_Transliteration"
}

export default constants