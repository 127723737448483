import Surahs from '../data/surah.json'

import HelperLocalStorage from "../util/helperLocalstorage";

import { useNavigate } from "react-router-dom";

import { HStack, Button, Text } from "@chakra-ui/react";
import { FaListOl } from "react-icons/fa";



const Surah = () => {

	const surahID = HelperLocalStorage.getSurahID()

	let navigate = useNavigate();

	return (

		<HStack mb={4}>

			<Button
				onClick={() => navigate('/surah')}
				leftIcon={<FaListOl />}>
			</Button>
			<Text fontSize={'sm'}>{" " + surahID} : {Surahs[surahID - 1].Trans}</Text>

		</HStack>

	);
}

export default Surah;
