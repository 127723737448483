import { useState } from "react";


// UI
import { Container, Divider } from "@chakra-ui/react";


// Component
import Reciter from './main-reciter'
import Surah from './main-surah'
import VerseStartAndEnd from "./main-verse-start-end";
import RepeatVerse from "./main-repeat-verse";
import SelectionLoop from "./main-selection-loop";
import RepeatAfterReciter from "./main-repeat-after-reciter";
import VerseInterval from './main-verse-interval'
import Audio from './audio'

import QuranScriptSelector from "./main-quran-script-selector";
import HelperLocalStorage from "../util/helperLocalstorage";
import WakeLockSetting from "./main-wake-lock-selector";
import Translit from "./main-translit";

/*
		Pattern:

		Each Child Component uses the local storage value, if none is set, it uses the values from Constants

		Less State is moving around now, as other components can simply check the local storage 
		They do not need to check for a value as it would have been set by the child component

		e.g. When play button is pressed, it can look up whether repeat verse is on or not.		

		This makes each child component more independent

		Changes all reflect our new pattern of setting the local storage or using the default value. 

		Result is lessing moving of data around.

		Part A:
		Components as much as possible fetches the local storage value and uses that, if not set, it uses AND updates the local storage with hard coded default values.

		Part B:
		Components needing data can then access the local storage.

*/

// Styles
const mainStyle = {
	marginBottom: "8rem"
}

const Main = () => {

	// Show/Hide the Audio Footer if any of the inputs are Valid/Invalid
	const [preventPlayback, setPreventPlayback] = useState<boolean>(false)

	// Show/Hide the User Config Section depending on Toggle Button on Audio Component
	const [displayQuranText, setDisplayQuranText] = useState(false)

	// Audio Component (Child) will update this status - used to make User Config section read only
	const [playStatus, setPlayStatus] = useState(false)
	const handlePlayStatusChange = (status: boolean) => {
		// console.log("status = " + status)
		setPlayStatus(status)
	}

	// BUG FIX: Quran Text not updating its "current verse" focus
	// We store the current verse in this component (previously audio)
	// Because if we change the start verse value, we need to update the child component(audio)
	// Which in turn updates the QuranText Component 
	// Had some issues with this inside audio!!! Now the Quran Text focuses properly
	// If start verse becomes = 2, then verseCurrent becomes = 2
	// On playing, the audio component (child) will handle the updating of the verseCurrent	
	const [verseCurrent, setCurrentVerse] = useState<number>(HelperLocalStorage.getVerseStart());


	return (

		<main style={mainStyle}>

			<Container
				maxW="container.sm"
				mt={5}
				pointerEvents={playStatus ? "none" : "auto"}
				bg={playStatus ? "gray.200" : ""}
			>
				{
					/*
	
						USER CONFIG SECTION
	
					*/
					// Displays the User Config if false, the Audio Component will get this prop and then Display the Quran Text
					// Quran Text has to be a child of Audio, because as the Audio Plays, it needs to know the current Verse so it can highlight it					
					displayQuranText === false &&

					<>

						{
							// CHILD: Choose Reciter
						}
						<Reciter />

						{
							// CHILD: Select Surah
						}
						<Surah />

						<Divider mb={5} />

						{
							// CHILD: Verse START and END
						}
						<VerseStartAndEnd
							setPreventPlayback={setPreventPlayback}
							setCurrentVerse={setCurrentVerse}
						/>


						{
							// CHILD: Repeat Verse
						}
						<RepeatVerse setPreventPlayback={setPreventPlayback} />

						{
							// CHILD: Verse Interval
						}
						<VerseInterval setPreventPlayback={setPreventPlayback} />

						<Divider mb={5} />

						{
							// CHILD: Enable/Disable Selection Loop
						}
						<SelectionLoop />

						{
							// CHILD: Enable/Disable Repeat after Reciter
						}
						<RepeatAfterReciter />

						<WakeLockSetting />

						<Translit />

						<Divider mb={5} />

						<QuranScriptSelector />


					</>
				}
			</Container>

			{
				/* 
						Audio Footer
				*/
				// If there is any invalid input in the config section, we hide the Audio Playback to prevent any issues
				preventPlayback === false &&

				<Audio
					onPlayStatusChange={handlePlayStatusChange}
					displayQuranText={displayQuranText}
					setDisplayQuranText={setDisplayQuranText}
					verseCurrent={verseCurrent}
					setCurrentVerse={setCurrentVerse}
				/>

			}

		</main >
	);
}

export default Main;
