import React from "react";

import Constants from '../data/constants'

import HelperLocalStorage from "../util/helperLocalstorage";

import { Heading, HStack, Radio, RadioGroup, Text } from '@chakra-ui/react'

/*
	Allow user to select a Quran Script
	Defaults to whatever is set in constants
	Save the Quran Type to Local Storage on updating state
	Quran Text Component can then pick up the users preference and show the appropiate Quran Text and Font	
*/

const QuranScriptSelector = () => {

	// Get Quran Font
	const [quranFont, setQuranFont] = React.useState(HelperLocalStorage.getQuranFont())

	// Update local storage if Quran Font Changes
	React.useEffect(() => {
		localStorage.setItem(Constants.KEY_QuranFontCurrent, quranFont)
	}, [quranFont])

	return (

		<RadioGroup onChange={setQuranFont} value={quranFont} defaultValue={Constants.KEY_QuranFontIndopak}>

			<Heading as="h4" size={"sm"} mb={2}>Quran Font</Heading>

			<HStack spacing={3}>

				<Radio
					value={Constants.KEY_QuranFontIndopak}
				>
					<Text fontSize={'sm'}>Indopak</Text>
				</Radio>

				<Radio
					value={Constants.KEY_QuranFontUthmani}
				>
					<Text fontSize={'sm'}>Uthmani</Text>
				</Radio>

				<Radio
					value={Constants.KEY_QuranFontUthmaniTajweed}
				>
					<Text fontSize={'sm'}>Uthmani (Tajweed)</Text>
				</Radio>


			</HStack>

		</RadioGroup>
	)
}

export default QuranScriptSelector;
