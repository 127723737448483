import { useState, useEffect } from "react";
import Constants from '../data/constants'
import scrollIntoView from 'scroll-into-view-if-needed'
import HelperLocalStorage from "../util/helperLocalstorage";
import QuranTextHeader from './quran-text-header'
import { Button, Container, HStack, Tag, Text, VStack } from "@chakra-ui/react";

import Transliteration from '../data/quran_transliteration.json'

/*  
	 Accept a Surah and Verse Number as prop
	 Mark Curent Verse
	 Filter Quran Text based on Surah 
	 Includes RunTime Cache of API Calls
*/

type Props = {
	currentVerse: number
}

const CACHE: any = {};


const QuranText = ({ currentVerse }: Props) => {

	// Store Verse to Display
	const [arrayVerses, setArrayVerses] = useState([])
	const [receivedVerses, setReceivedVerses] = useState(false)

	// Current Font in Local Storage OR Default Quran Font if the former is not found	
	const currentFont = HelperLocalStorage.getQuranFont()
	const surahID = HelperLocalStorage.getSurahID()
	const translit = HelperLocalStorage.getIsTransliteration()

	// Error Handling
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Filter Quran Data based on Surah entered
	useEffect(() => {

		// If Uthamni or Empty (somehow) we use Indopak
		let target_api_endpoint: string = Constants.QuranAPI_Indopak
		if (currentFont === Constants.KEY_QuranFontUthmani) {
			target_api_endpoint = Constants.QuranAPI_Uthmani
		}
		else if (currentFont === Constants.KEY_QuranFontUthmaniTajweed) {
			console.log("Uthmani (Tajweed) Selected")
			target_api_endpoint = Constants.QuranAPI_Uthmani_Tajweed
		}

		// cacheID is how a cache is identified against a unique request
		const cacheID: string = target_api_endpoint + surahID.toString()

		// look in cache and set response if present
		if (CACHE[cacheID] !== undefined) {
			setArrayVerses(CACHE[cacheID]);
			setReceivedVerses(true);
			console.log("using cached version")
			// console.log(CACHE[cacheID])
		} else {
			// else make sure loading set to true
			// setLoading(true);
			fetch(cacheID)
				.then(res => res.json())
				.then(
					(result) => {
						setArrayVerses(result.verses)
						setReceivedVerses(true)
						CACHE[cacheID] = result.verses;
						console.log("FETCH LIVE START:")
						console.log(CACHE[cacheID])
						console.log("FETCH LIVE END:")
						setError(null);
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
				)
				.catch((err) => {
					setError(err.message);
					setArrayVerses([]);
				})
				.finally(() => {
					setLoading(false);
				});

		}

	}, [currentFont, surahID]);


	// We only want to update this if currentVerse Changes
	// And in turn only if there actually verses to show
	useEffect(() => {

		if (receivedVerses) {
			const nodeElement = document.getElementById('scrollToMe')
			const node = nodeElement as Element
			scrollIntoView(node, { block: 'center', inline: 'center' })
		}
	}, [currentVerse, receivedVerses])


	const parseText = (text: string) => {

		let parsedText = text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

		return parsedText
	}


	return (

		<Container maxW='container.sm' mb={20} >


			{loading &&
				<Button
					isLoading
					loadingText='Loading'
					colorScheme='red'
					variant='outline'
					spinnerPlacement='start'
				/>}
			{error && (
				<div>{`There is a problem fetching the information, this feature requires a internet connection - ${error}`}</div>
			)}


			{
				receivedVerses === true &&
				/*        
				 DATA: ReferenceData has 4 Fields
				 1. Sura e.g. 1
				 2. Verse e.g. 2
				 3. eng
				 4. arb     
					 
				*/

				<>

					<QuranTextHeader />

					<VStack mb={20}>
						{

							arrayVerses.map((reference: any, index: number) => (
								<>
									<HStack
										id={currentVerse === index + 1 ? "scrollToMe" : ""}
										key={index}
										p={3}
										border={currentVerse === index + 1 ? '1px' : ''}
										borderColor={"red.300"}
										shadow='sm'
										dir={"rtl"}
										width={"100%"}
									>
										<Tag>{index + 1}</Tag>

										<VStack>
											{
												currentFont === Constants.KEY_QuranFontIndopak &&
												<span className="arabic-indopak">{parseText(reference.text_indopak)}</span>
											}

											{
												currentFont === Constants.KEY_QuranFontUthmani &&
												<Text className="arabic-uthmani">{reference.text_uthmani}</Text>
											}

											{
												currentFont === Constants.KEY_QuranFontUthmaniTajweed &&
												<Text className="arabic-uthmani-tajweed" dangerouslySetInnerHTML={{ __html: reference.text_uthmani_tajweed }}></Text>
											}

											{
												translit &&
												<Text key={"tl" + index} whiteSpace="pre-line" className="transliteration">{Transliteration[surahID - 1]["verses"][index]["tl"]}
												</Text>
											}


										</VStack>

									</HStack>


								</>
							))
						}
					</VStack>
				</>

			}

		</Container >
	);
}

export default QuranText;
