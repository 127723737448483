import { BrowserRouter, Route, Routes } from "react-router-dom";

import Main from '../components/main';
import ReciterList from '../components/list-reciter';
import SurahList from '../components/list-surah';

import Home from '../static/home'
import Header from '../static/header'
import About from '../static/about'

import Help from '../static/help'
import Page404 from '../static/404'
import ChangeLog from '../static/changelog'
import Contact from "../static/contact";


const AppRoutes = () => {

	return (

		<BrowserRouter>

			<Header />

			<Routes>

				<Route path="/" element={<Home />} />
				<Route path="/help" element={<Help />} />

				<Route path="/about" element={<About />} />

				<Route path="/main" element={<Main />} />
				<Route path="/reciters" element={<ReciterList />} />
				<Route path="/surah" element={<SurahList />} />

				<Route path="/changelog" element={<ChangeLog />} />
				<Route path="/contact" element={<Contact />} />

				<Route path="*" element={<Page404 />} />

			</Routes>

		</BrowserRouter>

	);
}

export default AppRoutes;