import React, { useEffect, useState } from 'react'

// Data / Util
import HelperLocalStorage from "../util/helperLocalstorage"
import Constants from "../data/constants"

// UI
import {
	FormControl,
	FormLabel,
	Switch,
	FormHelperText,
	HStack,
	Link
} from "@chakra-ui/react";

// Wake Lock
import { useWakeLock } from 'react-screen-wake-lock';
import { ExternalLinkIcon } from '@chakra-ui/icons';


const WakeLockSetting = () => {

	/*
			Component State
	*/

	const [wakeLockState, setWakeLockState] = useState<boolean>(HelperLocalStorage.getWakeLockSetting || false)
	/*
	
	Hooks
	
*/

	const { isSupported, released, request, release } = useWakeLock({

		onRequest: () => { },

		onError: () => { },

		onRelease: () => { },
	});


	// Wake Lock Setting Initialization
	useEffect(() => {

		// wakeLockSetting is string | null and typecast to boolean
		const wakeLockSetting = localStorage.getItem(Constants.SETTING_WAKE_LOCK)


		// setting doesnt exist: null && wake lock is supported
		if (wakeLockSetting === null && isSupported) {

			setWakeLockState(true)
			HelperLocalStorage.setWakeLock(true)
		}

		if (wakeLockSetting !== null && isSupported) {
			if (wakeLockSetting === 'true') {
				setWakeLockState(true)
			}
			else {
				setWakeLockState(false)
			}
		}

	}, [isSupported])

	const handleChange = () => {
		setWakeLockState(!wakeLockState)
		HelperLocalStorage.setWakeLock(!wakeLockState)
	}

	return (
		<FormControl
			mt={5}
			mb={5}
			isDisabled={!isSupported}
			display='flex'
			flexDirection="column"
			alignItems='start'>

			<HStack>

				<Switch
					id='wake-lock-setting'
					isChecked={wakeLockState}
					onChange={handleChange}
				/>
				<FormLabel htmlFor='wake-lock-setting' mb='0'>
					Keep Screen On
				</FormLabel>
			</HStack>
			{
				!isSupported &&
				<Link
					href='https://caniuse.com/wake-lock'
					isExternal={true}>
					<FormHelperText
						fontSize="sm"
						textColor="blue.400"
						textDecoration='underline'
					>
						Unvailable for your browser. Find out more{' '}

						<ExternalLinkIcon color='blue.400' mx='2px' />

					</FormHelperText>
				</Link>
			}
		</FormControl>
	)
}

export default WakeLockSetting