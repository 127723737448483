import React from 'react';

import Surah from '../data/surah.json'
import Verses from '../data/ayah.json'

import Constants from '../data/constants'

import HelperLocalStorage from '../util/helperLocalstorage'

import { Box, Container, Heading, HStack, SimpleGrid, Tag } from '@chakra-ui/react'
import { FaRegArrowAltCircleRight } from 'react-icons/fa';

import { useNavigate } from "react-router-dom";



const SurahList = () => {

	const [activeSurah, setActiveSurah] = React.useState(HelperLocalStorage.getSurahID());

	let navigate = useNavigate();

	
	const surahChanged = (newSurahID: number) => {

		// If no change has happened then just do nothing
		console.log(activeSurah)
		console.log(newSurahID)

		if (activeSurah !== newSurahID) {

			// Persist Reciter ID to LocalStorage
			localStorage.setItem(Constants.KEY_SurahID, JSON.stringify(newSurahID));
			setActiveSurah(newSurahID)

			// Update the Cache with the new Surah Verse Start and End
			// We only doing this because there is a change in Surah
			localStorage.setItem(Constants.KEY_VerseStart, JSON.stringify(1))
			localStorage.setItem(Constants.KEY_VerseEnd, JSON.stringify(Verses[newSurahID]))
		}

		// Go back to Home Screen

		navigate('/main')
	}


	return (

		<Container maxW='container.sm' mb={20} mt={10}>

			<Heading>Choose a Surah</Heading>

			<SimpleGrid spacing={3}>

				{

					Surah.map((surah) => (

						<HStack
							key={surah.ID.toString()}

							// The Surah ID starts from 1
							onClick={() => surahChanged(surah.ID)}
							// Active will be whatever is in local storage 
							cursor={'pointer'}

						>

							<Box fontSize={'sm'} >
								<Tag>
									{surah.ID}
								</Tag>
							</Box >

							{
								activeSurah === surah.ID &&
								<FaRegArrowAltCircleRight />
							}

							<Box >
								{Surah[surah.ID - 1].Trans}
							</Box >


							<Box className='arabic' fontSize={'sm'}>
								{Surah[surah.ID - 1].Arabic}
							</Box >


							<Box fontSize={'sm'} float={'right'}>
								{Surah[surah.ID - 1].Translation}
							</Box >

						</HStack>
					))
				}

			</SimpleGrid>

		</Container >
	)
}

export default SurahList;