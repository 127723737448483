import Constants from '../data/constants'

import { FaMinus } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { IconButton, Tag } from "@chakra-ui/react";


type Props = {
	playbackSpeed: number,
	setPlaybackSpeed: Function
}

const speedMax: number = 3.0
const speedMin: number = 0.5


const AudioSpeed = ({ playbackSpeed, setPlaybackSpeed }: Props) => {


	const handleSpeedChange = (value: number) => {

		const adjustedSpeed = playbackSpeed + value

		setPlaybackSpeed(adjustedSpeed)

		localStorage.setItem(Constants.KEY_PlaybackSpeed, JSON.stringify(adjustedSpeed));
	}


	return (

		<>

			<IconButton
				colorScheme='pink'
				aria-label='Decrease Playback Speed'
				onClick={() => handleSpeedChange(-0.25)}
				disabled={playbackSpeed === speedMin ? true : false}
				icon={<FaMinus />}
			/>


			<Tag minW={"50px"}>{playbackSpeed}x</Tag>


			<IconButton
				colorScheme='purple'
				aria-label='Increase Playback Speed'
				onClick={() => handleSpeedChange(0.25)}
				disabled={playbackSpeed === speedMax ? true : false}
				icon={<FaPlus />}
			/>

		</>
	);
}

export default AudioSpeed;
