import UnformattedReciters from '../data/reciters.json'

interface ReciterIF {
	bitrate?: string,
	name?: string,
	subfolder?: string
}



export module HelperReciters {

	export function getReciterData(reciterID: number) {

		/*
	
			Example of an entry. Note, the 0 key, was not used because we soemtimes remove reciters
			Thus, we have to "lookup" the 0 key value in this case 76 as per the JSON
			This function then simply returns the 
	
			0: "76"
			
					1:
					bitrate: "64kbps"
					name: "Ali Jaber"
					subfolder: "Ali_Jaber_64kbps"	
	
		*/
		const Reciters = Object.entries(UnformattedReciters)

		let bitrate: string = ""
		let name: string = ""
		let subfolder: string = ""

		// Extract the Reciter we looking for		
		Reciters.forEach(element => {
			if (element[0] === reciterID.toString()) {
				bitrate = element[1].bitrate
				name = element[1].name
				subfolder = element[1].subfolder
			}
		});

		const reciter: ReciterIF = {
			bitrate: bitrate,
			name: name,
			subfolder: subfolder
		}

		return reciter
	}
}

export default HelperReciters