import HelperReciters from "../util/helperReciters"
import HelperLocalStorage from "../util/helperLocalstorage";

import { FaUser } from "react-icons/fa";
import { Text, Button, HStack } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";




const Reciter = () => {

	let navigate = useNavigate();

	const reciterID = HelperLocalStorage.getReciterID()

	const reciter = HelperReciters.getReciterData(reciterID)


	return (

		<HStack mb={4}>

			<Button
				onClick={() => navigate('/reciters')}
				leftIcon={<FaUser />}>
			</Button>
			<Text fontSize={'sm'}>{reciter.name} {reciter.bitrate}</Text>

		</HStack>
	);
}

export default Reciter;
