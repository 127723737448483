import AboutOrg from './aboutOrg';

import { Box, Container, Heading } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import {
	ListItem,
	OrderedList,
	UnorderedList,
} from '@chakra-ui/react'


const AboutPage = () => {

	return (

		<Container maxW='container.sm' mt={10} mb={20}>

			<Heading>About</Heading>

			<AboutOrg />

			{
				// Project
			}
			<Box mt={10}>

				<Heading as='h2' fontSize={"medium"}>Project</Heading>

				<Text>The purpose of this Web App is to assist in the memorization or the revision of the Quran.</Text>
				<OrderedList>
					<ListItem>We build websites/apps that <Text as='u'>do not contain any advertisements</Text></ListItem>
					<ListItem>We use Progressive Web App technology that run on all devices thereby guaranteeing you access to the app from anywhere.</ListItem>
					<ListItem>This App can be used as a website on the desktop or installed on mobile devices - It does not require an app store.</ListItem>
					<ListItem>All our apps are <Text as='u'>FREE</Text> and wod appreciate you joining us in earning continous reward by donating to help us build more.</ListItem>
				</OrderedList>
			</Box>

			<Box mt={10}>
				<Heading as='h2' fontSize={"medium"} fontWeight={600}>Features</Heading>

				<UnorderedList>
					<ListItem>50+ Reciters from around the world</ListItem>
					<ListItem>Select any Surah from the Quran</ListItem>
					<ListItem>Begin Playback from any Verse</ListItem>
					<ListItem>End Playback from any Verse</ListItem>
					<ListItem>Repeat each Verse - X number of times</ListItem>
					<ListItem>Enable: Loop - To place your playback on endless loop</ListItem>
					<ListItem>Enable: Repeat After Reciter - to allow automatic pauses between verse playbacks</ListItem>
					<ListItem>Quran Reading Mode and flow the Reciter</ListItem>
					<ListItem>Playback Speed Contr</ListItem>
					<ListItem>All User Choices are Automatically Saved to the Device</ListItem>
				</UnorderedList>
			</Box>


			<Box mt={10}>
				<Heading as='h2' fontSize={"medium"} fontWeight={600}>Attribution</Heading>

				<UnorderedList>
					<ListItem>Verse by Verse Audio Recordings</ListItem>
					<ListItem>Quran.com API</ListItem>
				</UnorderedList>
			</Box>

			<Box mt={10}>
				<Heading as='h2' fontSize={"medium"} fontWeight={600}>Legal</Heading>
				<Text fontSize='sm'>
					All rights reserved. No part of this website may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of the MusListItemm Central NPO, except in the case of brief quotations embodied in critical reviews and certain other noncommercial uses permitted by copyright law.
				</Text>
			</Box>

		</Container>
	);
}

export default AboutPage;
