import React, { useState } from "react";

import Constants from '../data/constants'
import Verses from '../data/ayah.json'

import HelperLocalStorage from "../util/helperLocalstorage";

import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	FormControl,
	FormLabel,
	FormHelperText,
	FormErrorMessage,
	HStack
} from '@chakra-ui/react'


type Props = {
	setPreventPlayback: Function
	setCurrentVerse: Function
}



const VerseStartAndEnd = ({ setPreventPlayback, setCurrentVerse }: Props) => {

	const [verseStart, setVerseStart] = React.useState(HelperLocalStorage.getVerseStart())

	const [verseEnd, setVerseEnd] = React.useState(HelperLocalStorage.getVerseEnd())

	const surahID = HelperLocalStorage.getSurahID()

	/*
			Verse Start Error Handling
			The Verse Start value cannot be more than the Verse End
			Nor can it be 0
	*/

	const [verseStartInputError, setVerseStartInputError] = useState(false);
	const verseChangeStart = (event: any) => {

		// Update UI
		const value = parseInt(event)

		setVerseStart(event)

		// Valid Entries - update cache and input error state
		if (value && (value >= 1 && value <= verseEnd)) {
			setVerseStartInputError(false)
			setPreventPlayback(false)
			setCurrentVerse(value)
			localStorage.setItem(Constants.KEY_VerseStart, JSON.stringify(value))
		}
		// User removed any entry in the input field or entered an invalid value        
		else {
			setVerseStartInputError(true)
			setPreventPlayback(true)
		}

	}

	const verseStartUI = () => {

		return (

			<FormControl mb={4} >

				<HStack>

					<FormLabel minWidth={40} htmlFor='verse-start' >Start Verse</FormLabel>

					<NumberInput
						id="verse-start"
						defaultValue={verseStart}
						min={1}
						max={verseEnd}
						onChange={verseChangeStart}
						maxW={150}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>

				</HStack>

				{verseStartInputError ? (
					<FormHelperText>
						Minimum Input is 1
					</FormHelperText>
				) : (
					<FormErrorMessage>Input is required.</FormErrorMessage>
				)}

			</FormControl>


		)
	}



	/*
	
		Verse End
	
	*/

	const [verseEndInputError, setVerseEndInputError] = useState(false);
	const verseChangeEnd = (event: any) => {

		// Update UI
		setVerseEnd(event)

		// Get Integer Value to check range
		const value = parseInt(event)

		// Valie Entry (parsing to ensure types are the same)
		if (value && (value >= verseStart && value <= Verses[surahID])) {
			setPreventPlayback(false)
			setVerseEndInputError(false)
			localStorage.setItem(Constants.KEY_VerseEnd, JSON.stringify(value))
		}

		// User removed any entry in the input field or entered an invalid value
		else {
			setPreventPlayback(true)
			setVerseEndInputError(true)
		}

	}


	const verseStartEndUI = () => {

		return (

			<FormControl mb={5}>

				<HStack>

					<FormLabel minWidth={40} htmlFor='verse-end'>End Verse</FormLabel>

					<NumberInput
						id="verse-end"
						defaultValue={verseEnd}
						min={verseStart}
						max={Verses[surahID]}
						onChange={verseChangeEnd}
						maxW={150}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>

				</HStack>

				{verseEndInputError ? (
					<FormHelperText>
						Acceptable Range [{verseStart} - {Verses[surahID]}]
					</FormHelperText>
				) : (
					<FormErrorMessage>Input is required.</FormErrorMessage>
				)}

			</FormControl>
		)
	}


	return (
		<>
			{
				verseStartUI()
			}
			{
				verseStartEndUI()
			}
		</>
	)
}

export default VerseStartAndEnd;
