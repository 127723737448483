import React from 'react';

import Reciters from '../data/reciters.json'

import Constants from '../data/constants'

import HelperLocalStorage from '../util/helperLocalstorage'

import { Box, Container, Heading, ListItem, UnorderedList, Text, Alert, AlertIcon, Tag, HStack, SimpleGrid, Button } from '@chakra-ui/react'

import { useNavigate } from "react-router-dom";
import { FaRegArrowAltCircleRight } from 'react-icons/fa';





const ReciterList = () => {

	// Active is either in Local Storage (user-defined) or we use the Default Value		
	const [activeReciter, setActiveReciter] = React.useState(HelperLocalStorage.getReciterID());

	let navigate = useNavigate();

	// Manage User Changing Reciter
	const reciterChanged = (newReciterID: number) => {

		// Persist Reciter ID to LocalStorage
		localStorage.setItem(Constants.KEY_ReciterID, JSON.stringify(newReciterID));

		setActiveReciter(newReciterID)

		navigate("/main");

	}


	// Sort JSON Data Alphabetically
	/* 
		Each reciter (in this loop) has two values
		[0] > Reciter JSON ID
		[1] > The bitrate, sub-folder etc                               

		By using the JSON ID, if we remove reciters it won't break the App because we reference them using object notation using this ID
	*/
	const RecitersList = Object.entries(Reciters).sort((a, b) => {

		if (a[1].name > b[1].name) {
			return 1
		}

		if (a[1].name < b[1].name) {
			return -1
		}

		return 0

	})


	return (

		<Container maxW='container.sm' mb={20} mt={10}>

			<Heading>Choose a Reciter</Heading>

			<Box mb={5} mt={2}>
				<Text fontSize={'2xl'}>Sound Quality Guide:</Text>
				<Alert status='info'>
					<AlertIcon />
					<UnorderedList>
						<ListItem>64Kbps – AM radio quality</ListItem>
						<ListItem>96Kbps – FM radio quality</ListItem>
						<ListItem>128Kbps – Compact disk (CD) quality</ListItem>
						<ListItem>192Kbps or higher – High quality</ListItem>
					</UnorderedList>
				</Alert>
			</Box>

			<Box mb={10} mt={2}>
				<Button onClick={() => reciterChanged(Constants.DefaultReciterID)}>
					<Text>Reset to Default Reciter</Text>
				</Button>
			</Box>


			<SimpleGrid spacing={5}>
				{

					RecitersList.map((reciter, index) => (

						<HStack
							key={index}
							// The Reciters Object starts from 1				
							onClick={() => reciterChanged(parseInt(reciter[0]))}
							cursor={'pointer'}
						>
							{
								activeReciter === parseInt(reciter[0]) &&
								<FaRegArrowAltCircleRight display={"flex"} />
							}

							<Text>
								{reciter[1].name}
							</Text>

							<Tag>{reciter[1].bitrate}
							</Tag>

						</HStack>
					))
				}
			</SimpleGrid>


		</Container >

	)
}

export default ReciterList;