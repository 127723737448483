import React from "react";

import Constants from '../data/constants'

import HelperLocalStorage from "../util/helperLocalstorage";

import { Switch, FormLabel, FormControl } from '@chakra-ui/react'



const SelectionLoop = () => {

	const [loop, setLoop] = React.useState(HelperLocalStorage.getIsLoopSelectionOn())

	// Loop Value Change - update UI and Cache
	const loopChanged = () => {
		setLoop(!loop)
		localStorage.setItem(Constants.KEY_LoopListenMode, JSON.stringify(!loop));
	}

	return (

		<FormControl display='flex' alignItems='center' mb={5}>

			<Switch id="loop"

				isChecked={loop}
				onChange={loopChanged}
			/>
			<FormLabel htmlFor='loop' mb='0' ml="1.5">
				Loop Selection
			</FormLabel>

		</FormControl>

	)
}

export default SelectionLoop;
