import React from "react";

import Constants from '../data/constants'

import HelperLocalStorage from "../util/helperLocalstorage";

import { FormLabel, FormControl, FormErrorMessage, FormHelperText, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react'


type Props = {
	setPreventPlayback: Function,
}


const minInput: number = 0
const maxInput: number = 60


const VerseIntervals = ({ setPreventPlayback }: Props) => {

	const [verseInterval, setVerseInterval] = React.useState(HelperLocalStorage.getVerseInterval())

	const [verseRepeatInputError, setVerseRepeatInputError] = React.useState(false);


	const verseIntervalChanged = (event: any) => {

		// console.log({ event })

		let val = parseInt(event)

		setVerseInterval(event)

		// Valid Entry
		if (val >= minInput && val <= maxInput) {

			setPreventPlayback(false)
			setVerseRepeatInputError(false)
			localStorage.setItem(Constants.KEY_VerseInterval, JSON.stringify(event))
		}

		// User removed any entry in the input field or entered an invalid value
		else {
			setPreventPlayback(true)
			setVerseRepeatInputError(true)
		}


	}


	return (

		<FormControl mb={5}>

			<HStack>

				<FormLabel minWidth={40} htmlFor='repeat-verse'>Pause between Verses (sec)</FormLabel>

				<NumberInput
					id="repeat-verse"
					inputMode={"numeric"}
					min={minInput}
					max={maxInput}
					value={verseInterval}
					onChange={verseIntervalChanged}
					maxW={150}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>

			</HStack>

			{verseRepeatInputError ? (
				<FormHelperText>
					Range [{minInput.toString()}-{maxInput.toString()}]
				</FormHelperText>
			) : (
				<FormErrorMessage>Input is required.</FormErrorMessage>
			)}

		</FormControl>

	)
}

export default VerseIntervals;
