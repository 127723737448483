import Surah from '../data/surah.json'

import HelperLocalStorage from "../util/helperLocalstorage";

import { Center, Text } from "@chakra-ui/react";


const QuranTextHeader = () => {

	const surahID = HelperLocalStorage.getSurahID()

	return (
		<Center>
			<Text>
				<strong>Surah {surahID}: </strong>
				<span>{Surah[surahID - 1].Trans} | </span>
				<span className="arabic">{Surah[surahID - 1].Arabic}</span>
				<span> | {Surah[surahID - 1].Translation}</span>
			</Text>
		</Center>
	);
}

export default QuranTextHeader;